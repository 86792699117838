
table {
    border-color: $softBlack;
}

.ant-table-thead {
    font-size: 20px;
    tr {
        th {
            padding: 30px;
            background-color: transparent;
        }
    }
}

.ant-table-tbody {
    tr {
        td {
            padding: 30px;
            font-size: 16px;
            font-family: 'lato';
            color: black;
            font-weight: medium;

            .ant-tag {
                height: 30px;
                border-radius: 100px;
                color: white;
                font-size: 16px;
                padding:5px 10px;
            }
            .ant-tag-red {
                background-color: $red;
                border-color: $red;
            }
            .ant-tag-green {
                background-color: $green;
                border-color: $green;
            }
            .ant-tag-yellow {
                background-color: $yellow;
                border-color: $yellow;
            }
            span.ant-tag.view {
                background-color: 'white';
                border-color: $blue;
                color: $fontBlack;
                font-weight: 600;
            }
            span.ant-tag.tag {
                background-color: $grey;
                border-color: $grey;
                color: $fontBlack;
                font-weight: 600;
                border-radius: 4px;
                height: 40px;
                padding: 10px;
            }

            a {
                font-weight: bold;
                color: black;
                text-transform: capitalize;
            }
        }
    }
}