.compass {
  h3 {
    font-weight: 400 !important;
  }
  .header-strip {
    background: repeating-linear-gradient(
      110deg,
      #f1f1f1,
      #f1f1f1 2px,
      #f7fffd 2px,
      #f7fffd 12px
    );
    border-bottom: 1px solid #f1f1f1;
  }
  h1.ui.header {
    font-family: Montserrat;
    font-weight: 200;
    font-size: 44px;
    letter-spacing: 0.05em;
    line-height: 70px;
    text-align: center;
    color: #2d393b;
    text-transform: uppercase;
    .sub.header {
      font-family: Lato;
      font-weight: normal;
      letter-spacing: normal;

      // font-size: 20px !important;
      color: #3d494b;
      text-transform: none;
    }
  }
  .card-container {
    // margin: 25px 0;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, 350px);
    // flex-flow: row wrap;
    // gap: 15px;
  }
  .nav-card {
    border-radius: 10px;
    border: rgba(0, 0, 0, 0.1) 1px solid;
    background: #fff;
    padding: 25px 25px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    // align-content: center;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    &:hover {
      box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.16);
      cursor: pointer;
    }
     > img {
      height: 30px;
      width: 30px;
    }
     > .card-name {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      margin-left: 20px;
      font-family: Lato;
      font-weight: normal;
      font-size: 18px;
      text-align: left;
      color: #6e7277;
    }
  }
}

.FullWidthWrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.sub-heading {
  padding: 0 !important;
  height: 90vh;
}

header {
  height: 100px;
  max-height: 100px;
  padding: 30px;
  position: relative;

  h1 {
    margin: 0;
    position: absolute;
    margin-left: 60px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.animate-width {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
.portalNav {
  margin-top: 25vh;
  // .item > .header {
  //   padding-left: 30px !important;
  //   font-family: Montserrat !important;
  //   font-weight: 600 !important;
  //   font-size: 14px !important;
  //   letter-spacing: 0.1em !important;
  //   line-height: 20px !important;
  //   text-align: left !important;
  //   color: #b2b5b6 !important;
  //   text-transform: uppercase;
  //   padding: 5px 0;
  // }
  & .ui.list .list > .item:first-child,
  .ui.list > .item:first-child,
  ol.ui.list li:first-child,
  ul.ui.list li:first-child {
    margin-top: 0 !important;
    // padding-top: 0 !important;
  }
  .item.navOption {
    img {
      height: 50px;
    }
    padding: 20px 0 20px 10px !important;
    font-family: Lato;
    font-size: 18px;
    color: #939498 !important;
    transition: color 0.1s ease, border 0.1s ease;
    text-align: center;
    &.active {
      color: #3dbeaf !important;
      font-weight: bold;
      background: #f8f8f8 !important;
      border-left: #3dbeaf 5px solid;
      padding: 20px 0 20px 5px !important;
    }
    &:hover {
      color: #3dbeaf !important;
      border-left: #3dbeaf 5px solid;
      cursor: pointer;
    }
  }
}

.headerSearch > {
  .ui.input {
    width: 100% !important;
    input {
      // border: 0;
      height: 50px !important;
      border-radius: 10px !important;
      background: #fff !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.headerAttached {
  &.ui.pointing.secondary.menu {
    padding-left: 60px;
    box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.16);
    border: 0;
    .item {
      // min-width: 100px;
      // text-align: center !important;
      margin: 0 20px;
      font-family: Lato;
      font-weight: normal;
      font-size: 14px;
      color: #939498;
      &:hover {
        color: #3dbeaf;
        cursor: pointer;
      }
      &.active {
        font-weight: 800;
        color: #3dbeaf;
        border-color: #3dbeaf;
        &:hover {
          color: #3dbeaf;
          border-color: #3dbeaf;
        }
      }
    }
  }
}

.mainContent {
  height: calc(100vh - 135px) !important;
  overflow-y: hidden;
}

#userDashboard {
  .dataTitle {
    margin-top: 25px;
    font-family: Lato;
    font-weight: 800;
    font-size: 14px;
    text-transform: lowercase;
  }
  .ui.card {
    height: 100%;
    .description {
      text-align: center;
    }
    .header {
      font-family: Montserrat;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 26px;
      text-align: left;
      color: #2d393b;
    }
    .extra {
      background: #3dbeaf;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
      transition: background 0.1s ease;
      &:hover {
        background: #329b8f;
      }
      & a {
        color: #f8f8f8;
      }

      &.disabled {
        background: #adadad;
      }
    }
  }
}
