$black: #333333;
$white:#ffffff;
$color-primary: #007969;
$secondary:#FFB500;
$grey: #ebedf0;
$green: #007969;
$blue: #0A84FF;
$red: #FF543E;
$yellow: #FFB500;
$softGrey: #DDDDDD;
$softBlack: #2D393B;
$fontBlack: #3E3F42;
$transparentBlack:rgba(0,0,0,.25);

$transitions-primary: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
$shadow-depth-1: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-depth-2: 0 5px 10px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
$shadow-depth-3: 0 10px 20px rgba(0, 0, 0, 0.1), 0 20px 40px rgba(0, 0, 0, 0.1);
$shadow-depth-4: 0 10px 30px rgba(0, 0, 0, 0.1), 0 30px 60px rgba(0, 0, 0, 0.1);
$shadow-depth-5: 0 20px 40px rgba(0, 0, 0, 0.1), 0 40px 80px rgba(0, 0, 0, 0.1);

$responsive-large: 77.5em; /* (1240px) */
$responsive-medium: 60em; /* (960px) */
$responsive-small: 40em; /* (640px) */
