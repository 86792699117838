@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:ital,wght@0,100;0,300;0,500;0,600;0,700;0,800;0,900;1,400&display=swap);
/* Temp CSS until theming can be handled. */

.green-text {
    color: #34c28e !important;
}

.red-text {
    color: #c24734 !important;
}

.ui.primary.button:not(.basic) {
    background-color: #34AEC2 !important;
}

.ui.green.button:not(.basic) {
    background-color: #34c28e !important;
}

.ui.red.button:not(.basic) {
    background-color: #c24734 !important;
}

.ui.red.message:not(.basic) {
    background-color: #f9d0d1 !important;
    color: #c24734 !important;
    box-shadow: 0 0 0 1px #c24734 inset, 0 0 0 0 transparent !important;
}

.ui.red.message .header {
    color: #c24734 !important;
}

.ui.green.message {
    background-color: #dff4eb !important;
    color: #34c28e !important;
    box-shadow: 0 0 0 1px #34c28e inset, 0 0 0 0 transparent !important;
}

.ui.green.message .header {
    color: #34c28e !important;
}

/* (1240px) */
/* (960px) */
/* (640px) */
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; }

#root {
  position: relative;
  width: 100%;
  height: 100%; }

#root > section {
  position: relative;
  width: 100%;
  height: 100%; }

.login-graphic {
  height: 100%;
  width: 100%;
  padding-top: 10vh; }

h1 {
  font-size: 24px;
  font-family: lato;
  font-weight: bold; }

h2 {
  font-size: 20px;
  font-family: lato;
  font-weight: bold; }

h5 {
  font-size: 18px;
  font-family: lato;
  font-weight: bold; }

.ant-form-item-label > label {
  font-weight: bold; }

.ant-input, .ant-picker, .ant-form-item-label, .ant-form-item-label > label, .ant-form-item-control-input, .ant-select .ant-select-selector {
  height: 40px; }

.ant-btn-primary {
  background-color: #007969;
  border: none;
  border-radius: 0;
  height: 40px;
  font-size: 18px;
  font-family: lato; }

.ant-btn-primary:hover {
  background-color: #2d393b; }

.ant-btn-secondary {
  background-color: #ffb500;
  border: none;
  border-radius: 0;
  height: 40px;
  font-size: 18px;
  font-family: lato;
  color: white; }

.ant-btn-secondary:hover {
  background-color: #ca9100;
  color: white; }

.ant-menu-inline .ant-menu-item::after {
  border-right: none; }

.anticon {
  font-size: 18px !important; }

.ant-input, .ant-picker {
  border: none; }

.ant-cascader-menu-item-active {
  background-color: #ffffe6; }

.ant-picker-range .ant-picker-active-bar {
  background: #ffb500; }

.ant-input:focus, .ant-picker:focus, .ant-picker-focused {
  border: 1px Solid #ffb500;
  border-color: #ffb500 !important;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  -moz-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  background: white; }

.ant-input:hover, .ant-picker:hover {
  background: white;
  border: 1px Solid #ffb500;
  border-color: #ffb500 !important; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #ffb500;
  border-color: #ffb500; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #ffb500;
  border-color: #d89b00;
  color: #ffffe6; }

.ant-radio-button-wrapper:hover {
  color: #ffb500; }

.ant-layout {
  background: #fbfbfd; }

.ant-layout-sider {
  border-right: 2px solid #eaedf3; }

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none; }

.register-form {
  margin-top: 20vh !important; }

.main-select
.content {
  padding: 20px; }

.ant-layout-content {
  background: white; }

.ant-card {
  height: 165px;
  padding: 20px 0;
  background-color: #f9f9f9;
  border: none !important;
  text-align: center; }
  .ant-card .ant-statistic-title {
    font-size: 16pt;
    color: #2d393b; }
  .ant-card .ant-statistic-content-prefix, .ant-card .ant-statistic-content-value-decimal {
    color: #007869;
    font-size: 22pt !important; }
  .ant-card .ant-statistic-content-value {
    color: #007869;
    font-size: 32pt !important; }

.FormWrapper {
  max-width: 400px;
  margin: 25vh auto !important;
  background: #ffffff; }

.EmailConfirmFormWrapper {
  display: flex;
  flex-flow: wrap row;
  max-width: 50%;
  width: 100%;
  margin: 7% auto !important;
  padding: 42px 24px 50px !important;
  background: #ffffff;
  justify-content: space-around; }
  .EmailConfirmFormWrapper input {
    text-align: center; }

.CardWrapper {
  height: 100vh;
  padding: 20% 50px;
  background: #007969;
  color: #ffffff; }

.ant-card-head-title {
  font-size: 1.25rem; }

.ant-card-body p {
  font-size: 1rem;
  margin: 0; }

.ant-card-body .ant-tag {
  height: 30px;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  padding: 5px 10px; }

.ant-card-body .ant-tag-red {
  background-color: #FF543E;
  border-color: #FF543E; }

.ant-card-body .ant-tag-green {
  background-color: #007969;
  border-color: #007969; }

.ant-card-body .ant-tag-yellow {
  background-color: #FFB500;
  border-color: #FFB500; }

.ant-card-bordered {
  border-color: #007969;
  margin-top: 30px;
  border-radius: 4px; }

.ant-layout-sider-trigger {
  background: #007969 !important; }

.ant-layout-sider-children {
  background-color: white; }

.ant-menu-root.ant-menu-inline {
  height: 100%; }

.side-bar {
  max-width: 400px; }

.ant-layout-header {
  height: 110px; }

.ant-menu-vertical .ant-menu-item {
  margin-top: 0; }

.sider-collapsed .ant-menu-item {
  height: 60px;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  font-family: lato;
  color: #3e3f42; }

.ant-layout-sider-collapsed {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }
  .ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 100px !important; }
  .ant-layout-sider-collapsed .main-select .ant-menu-item-selected {
    width: 60px; }

.main-select .ant-menu-item:hover {
  color: #007969; }

.main-select .ant-menu-item-selected {
  border-radius: 100px;
  width: 90%;
  border: 2px solid #359487; }

.main-select ul li {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.main-select .ant-menu-item {
  height: 60px !important;
  padding: 10px 20px !important;
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: lato;
  color: #3e3f42; }

.main-select .ant-menu:not(.ant-menu-horizontal),
.main-select .ant-menu-item-selected {
  background-color: #fafbfc !important;
  color: #007969; }

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none !important; }

.bottom-select {
  bottom: 50px;
  position: absolute;
  width: 100%;
  border-right: none;
  padding: 20px 0 !important; }
  .bottom-select ul li {
    margin: 20px;
    font-size: 16px;
    font-weight: bold; }
    .bottom-select ul li:hover {
      color: #007969; }
  .bottom-select .ant-menu-item-selected {
    background-color: #fafbfc !important; }
  .bottom-select .ant-menu-item {
    padding: 10px 20px !important;
    margin: 0 20px; }

.ant-menu-item-selected {
  background-color: #fafbfc; }

::selection {
  color: #007969;
  background: #fafbfc; }

.bottom-select ul .ant-menu-item-selected {
  color: #007969;
  background-color: #fff;
  width: 90%;
  border: 0; }

.bottom-select ul .ant-menu-item {
  height: 50px;
  padding: 0px 20px; }

.property-select {
  height: 90vh;
  overflow: hidden; }
  .property-select ul li {
    padding: 30px 0;
    margin: 0; }
  .property-select .ant-menu-item {
    height: 90px !important;
    padding: 30px !important;
    font-size: 18px;
    font-weight: bold;
    color: #8D8D8D; }
    .property-select .ant-menu-item:hover {
      color: #007969; }
  .property-select .ant-menu-item-selected {
    background-color: transparent !important;
    color: #007969 !important;
    border-left: 3px solid #007969;
    z-index: 199; }

.back-icon {
  margin-right: 20px;
  background-color: #ebedf0;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  font-size: 18px; }
  .back-icon svg {
    height: 30px;
    margin-top: 5px;
    padding: 5px;
    color: black;
    width: auto; }

.ant-menu-item:not(.ant-menu-item-disabled) .anticon,
.ant-menu-item:not(.ant-menu-item-selected) .anticon {
  color: black;
  font-size: 18px; }

.ant-menu-item-disabled .anticon {
  color: rgba(0, 0, 0, 0.25) !important; }

.ant-menu-item-selected .anticon {
  color: #007969 !important;
  margin-top: 4px;
  margin-left: -2px; }

.ant-menu-item {
  padding: 13px 18px !important; }
  .ant-menu-item .anticon svg {
    width: 20px;
    height: auto; }

.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
  padding: 13px 18px !important; }

.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
  line-height: 0; }

.table-icon {
  padding: 10px;
  margin-right: 10px;
  background: #ebedf0;
  border-radius: 100px; }

table {
  border-color: #2D393B; }

.ant-table-thead {
  font-size: 20px; }
  .ant-table-thead tr th {
    padding: 30px;
    background-color: transparent; }

.ant-table-tbody tr td {
  padding: 30px;
  font-size: 16px;
  font-family: 'lato';
  color: black;
  font-weight: medium; }
  .ant-table-tbody tr td .ant-tag {
    height: 30px;
    border-radius: 100px;
    color: white;
    font-size: 16px;
    padding: 5px 10px; }
  .ant-table-tbody tr td .ant-tag-red {
    background-color: #FF543E;
    border-color: #FF543E; }
  .ant-table-tbody tr td .ant-tag-green {
    background-color: #007969;
    border-color: #007969; }
  .ant-table-tbody tr td .ant-tag-yellow {
    background-color: #FFB500;
    border-color: #FFB500; }
  .ant-table-tbody tr td span.ant-tag.view {
    background-color: 'white';
    border-color: #0A84FF;
    color: #3E3F42;
    font-weight: 600; }
  .ant-table-tbody tr td span.ant-tag.tag {
    background-color: #ebedf0;
    border-color: #ebedf0;
    color: #3E3F42;
    font-weight: 600;
    border-radius: 4px;
    height: 40px;
    padding: 10px; }
  .ant-table-tbody tr td a {
    font-weight: bold;
    color: black;
    text-transform: capitalize; }

.ant-switch-checked {
  background-color: #007969; }

html {
  --antd-wave-shadow-color: #007969 !important; }

.compass-tabs .ant-tabs-tab,
.compass-tabs .ant-tabs-tab-active {
  font-size: 20px;
  font-weight: bold;
  font-family: 'lato';
  padding: 20px 10px;
  margin: 0 50px 0 0; }

.compass-tabs .ant-tabs-tab-btn {
  font-weight: bold !important;
  width: 150px;
  text-align: center; }

.compass-tabs .ant-tabs-tab-active {
  border-color: #007969; }
  .compass-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #007969; }

.compass-tabs .ant-tabs-tab:hover {
  color: #007969; }

.compass-tabs .ant-tabs-ink-bar {
  background: #007969; }

.LoginBackground {
  height: 100vh;
  overflow: hidden;
  top: 0;
  position: absolute;
  width: 100vw;
  background: #007969; }

.LoginLeftSection {
  height: 100vh;
  top: 0;
  position: absolute;
  left: 0;
  width: 50vw;
  background-repeat: repeat;
  background-color: #007969; }

.LoginRightSection {
  height: 100vh;
  top: 0;
  right: 0;
  position: absolute;
  width: 50vw;
  background: #ffffff; }
  @media (max-width: 768px) {
    .LoginRightSection {
      width: 100vw !important;
      padding: 20px; }
      .LoginRightSection form.register-form {
        margin-top: 0 !important; } }

.compass h3 {
  font-weight: 400 !important; }

.compass .header-strip {
  background: repeating-linear-gradient(110deg, #f1f1f1, #f1f1f1 2px, #f7fffd 2px, #f7fffd 12px);
  border-bottom: 1px solid #f1f1f1; }

.compass h1.ui.header {
  font-family: Montserrat;
  font-weight: 200;
  font-size: 44px;
  letter-spacing: 0.05em;
  line-height: 70px;
  text-align: center;
  color: #2d393b;
  text-transform: uppercase; }
  .compass h1.ui.header .sub.header {
    font-family: Lato;
    font-weight: normal;
    letter-spacing: normal;
    color: #3d494b;
    text-transform: none; }

.compass .card-container {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 350px); }

.compass .nav-card {
  border-radius: 10px;
  border: rgba(0, 0, 0, 0.1) 1px solid;
  background: #fff;
  padding: 25px 25px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex; }
  .compass .nav-card:hover {
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.16);
    cursor: pointer; }
  .compass .nav-card > img {
    height: 30px;
    width: 30px; }
  .compass .nav-card > .card-name {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-left: 20px;
    font-family: Lato;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #6e7277; }

.FullWidthWrapper {
  position: relative;
  display: flex;
  width: 100%; }

.sub-heading {
  padding: 0 !important;
  height: 90vh; }

header {
  height: 100px;
  max-height: 100px;
  padding: 30px;
  position: relative; }
  header h1 {
    margin: 0;
    position: absolute;
    margin-left: 60px;
    top: 50%;
    transform: translateY(-50%); }

.animate-width {
  transition: width 1s ease-in-out; }

.portalNav {
  margin-top: 25vh; }
  .portalNav .ui.list .list > .item:first-child,
  .portalNav .ui.list > .item:first-child,
  .portalNav ol.ui.list li:first-child,
  .portalNav ul.ui.list li:first-child {
    margin-top: 0 !important; }
  .portalNav .item.navOption {
    padding: 20px 0 20px 10px !important;
    font-family: Lato;
    font-size: 18px;
    color: #939498 !important;
    transition: color 0.1s ease, border 0.1s ease;
    text-align: center; }
    .portalNav .item.navOption img {
      height: 50px; }
    .portalNav .item.navOption.active {
      color: #3dbeaf !important;
      font-weight: bold;
      background: #f8f8f8 !important;
      border-left: #3dbeaf 5px solid;
      padding: 20px 0 20px 5px !important; }
    .portalNav .item.navOption:hover {
      color: #3dbeaf !important;
      border-left: #3dbeaf 5px solid;
      cursor: pointer; }

.headerSearch > .ui.input {
  width: 100% !important; }
  .headerSearch > .ui.input input {
    height: 50px !important;
    border-radius: 10px !important;
    background: #fff !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important; }

.headerAttached.ui.pointing.secondary.menu {
  padding-left: 60px;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.16);
  border: 0; }
  .headerAttached.ui.pointing.secondary.menu .item {
    margin: 0 20px;
    font-family: Lato;
    font-weight: normal;
    font-size: 14px;
    color: #939498; }
    .headerAttached.ui.pointing.secondary.menu .item:hover {
      color: #3dbeaf;
      cursor: pointer; }
    .headerAttached.ui.pointing.secondary.menu .item.active {
      font-weight: 800;
      color: #3dbeaf;
      border-color: #3dbeaf; }
      .headerAttached.ui.pointing.secondary.menu .item.active:hover {
        color: #3dbeaf;
        border-color: #3dbeaf; }

.mainContent {
  height: calc(100vh - 135px) !important;
  overflow-y: hidden; }

#userDashboard .dataTitle {
  margin-top: 25px;
  font-family: Lato;
  font-weight: 800;
  font-size: 14px;
  text-transform: lowercase; }

#userDashboard .ui.card {
  height: 100%; }
  #userDashboard .ui.card .description {
    text-align: center; }
  #userDashboard .ui.card .header {
    font-family: Montserrat;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 26px;
    text-align: left;
    color: #2d393b; }
  #userDashboard .ui.card .extra {
    background: #3dbeaf;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    transition: background 0.1s ease; }
    #userDashboard .ui.card .extra:hover {
      background: #329b8f; }
    #userDashboard .ui.card .extra a {
      color: #f8f8f8; }
    #userDashboard .ui.card .extra.disabled {
      background: #adadad; }

