.back-icon {
    margin-right: 20px;
    background-color:$grey;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    font-size: 18px;

    svg {
        height:30px;
        margin-top: 5px;
        padding: 5px;
        color: black;
        width: auto;
    }
}
.ant-menu-item:not(.ant-menu-item-disabled),
.ant-menu-item:not(.ant-menu-item-selected) {
    .anticon {
        color: black;
        font-size: 18px;
    }
}

.ant-menu-item-disabled{
    .anticon {
        color: rgba(0, 0, 0, 0.25) !important;
    }
}
.ant-menu-item-selected {
    
    .anticon {
        color: $color-primary !important;
        margin-top: 4px ;
        margin-left: -2px;
    }
}
.ant-menu-item {
    padding: 13px 18px !important;
    .anticon {
        svg {
            width: 20px;
            height: auto;
        }
    }
    
}

.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
    padding: 13px 18px !important;
}

.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
    line-height: 0;
}

.table-icon {
    padding: 10px;
    margin-right: 10px;
    background: $grey;
    border-radius: 100px;
}