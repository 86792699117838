/* Temp CSS until theming can be handled. */

.green-text {
    color: #34c28e !important;
}

.red-text {
    color: #c24734 !important;
}

.ui.primary.button:not(.basic) {
    background-color: #34AEC2 !important;
}

.ui.green.button:not(.basic) {
    background-color: #34c28e !important;
}

.ui.red.button:not(.basic) {
    background-color: #c24734 !important;
}

.ui.red.message:not(.basic) {
    background-color: #f9d0d1 !important;
    color: #c24734 !important;
    box-shadow: 0 0 0 1px #c24734 inset, 0 0 0 0 transparent !important;
}

.ui.red.message .header {
    color: #c24734 !important;
}

.ui.green.message {
    background-color: #dff4eb !important;
    color: #34c28e !important;
    box-shadow: 0 0 0 1px #34c28e inset, 0 0 0 0 transparent !important;
}

.ui.green.message .header {
    color: #34c28e !important;
}
