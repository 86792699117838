html {
     --antd-wave-shadow-color: #007969 !important;
}

.compass-tabs {
    .ant-tabs-tab,
    .ant-tabs-tab-active {
        font-size: 20px;
        font-weight: bold;
        font-family: 'lato';
        padding: 20px 10px;
        margin: 0 50px 0 0;
    }
    .ant-tabs-tab-btn {
        font-weight: bold !important;
        width: 150px;
        text-align: center;
    }
    .ant-tabs-tab-active {
        border-color: $color-primary;
        
        .ant-tabs-tab-btn {
            color: $color-primary;
        }
    }
    .ant-tabs-tab {
        &:hover {
            color: $color-primary;
        }
    }
    .ant-tabs-ink-bar {
        background: $color-primary;
    }
}
